<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import loadSections from "@/mixins/loadSections";
import firebaseBindOrder from "@/mixins/firebase/bindOrder";

export default {
  name: "MenuOrderDetailsPaymentPartial",

  metaInfo: { title: "Menu Order Details Payment Partial" },

  extends: View,

  mixins: [
    firebaseBindOrder,
    loadSections([
      "layout-header-menu-with-return-button-and-title",
      "public-menu-order-details-payment-partial",
    ]),
  ],
};
</script>
